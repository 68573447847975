import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { MonthlyPunctualityDto } from '../common/models/monthly_punctuality_stats_dto';
import { mapVendorName } from '../common/utils/string_fns';

interface Props {
  data: MonthlyPunctualityDto[];
}

const VendorPunctualityChart: React.FC<Props> = ({ data }) => {
  // Function to format the date
  const formatMonth = (dateStr: string) => {
    const parts = dateStr.split(' ')
    return [parts[0].slice(0, 3), parts[1]].join(' ')
  };

  // Transform the data and add index information for positioning
  const transformedData = data.flatMap((monthData, monthIndex) => 
    monthData.data.map((vendorData, vendorIndex) => ({
      xPos: `${monthIndex}-${vendorIndex}`,  // Create a unique x-axis position
      month: formatMonth(monthData.month),
      monthIndex,
      vendor: mapVendorName(vendorData.vendor),
      vendorIndex,
      isLastInMonth: vendorIndex === monthData.data.length - 1,
      isFirstInMonth: vendorIndex === 0,
      early: vendorData.punctuality.early / 100,
      onTime: vendorData.punctuality.onTime / 100,
      delayed: vendorData.punctuality.delayed / 100
    }))
  );

  // Get unique months and their middle positions for labels
  // const months = Array.from(new Set(transformedData.map(d => d.month)))
  //   .map(month => {
  //     const monthData = transformedData.filter(d => d.month === month);
  //     const firstIndex = transformedData.findIndex(d => d.month === month);
  //     const lastIndex = transformedData.findLastIndex(d => d.month === month);
  //     return {
  //       name: month,
  //       position: (firstIndex + lastIndex) / 2
  //     };
  //   });

  // Calculate divider positions
  const monthDividers = transformedData
    .filter(d => d.isLastInMonth)
    .map(d => d.xPos);

  const colors = {
    early: '#046E9F',    // Dark Blue
    onTime: '#43B149',   // Dark Green
    delayed: '#D43535'   // Red
  };

  // Custom tick component for vertical vendor names
  const CustomXAxisTick = (props: any) => {
    const { x, y, payload } = props;
    const data = transformedData.find(d => d.xPos === payload.value);
    
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={-5}
          y={0}
          textAnchor="end"
          fill="#666"
          transform="rotate(-90)"
        >
          {data?.vendor}
        </text>
        {/* Add month label if this is the middle position for the month */}
        {data?.isFirstInMonth && (
          <text
            x={0}  // Adjust this value to move the month label left/right
            y={140}  // Adjust this value to move the month label up/down
            textAnchor="start"
            fill="#666"
          >
            {data.month}
          </text>
        )}
      </g>
    );
  };

  return (
    <Paper 
      elevation={2} 
      sx={{ 
        p: 2,
        height: '99%',
        width: '100%',
        bgcolor: '#f0f0f0',
      }}
    >
      <Typography variant="h6" gutterBottom align="center">
        Historical Data
      </Typography>
      <Box sx={{ width: '100%', height: '90%' }}>
        <ResponsiveContainer>
          <BarChart
            data={transformedData}
            margin={{ top: 20, right: 30, left: 20, bottom: 70 }}
            stackOffset="expand"
            barSize={40}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="xPos"
              interval={0}
              height={90}
              tick={CustomXAxisTick}
              axisLine={true}
            />
            <YAxis 
              tickFormatter={(value) => `${Math.round(value * 100)}`}
              label={{ value: 'Punctuality by Vendor and Month', angle: -90, position: 'insideLeft', dy: 150 }}
            />
            {/* Add reference lines between months */}
            {monthDividers.map((position, index) => (
              <ReferenceLine
                key={`divider-${index}`}
                x={position}
                stroke="#666"
                strokeWidth={1}
                ifOverflow="extendDomain"
              />
            ))}
            <Tooltip
              formatter={(value: number, name: string) => [
                `${(value * 100).toFixed(1)}%`,
                name.charAt(0).toUpperCase() + name.slice(1)
              ]}
              labelFormatter={(label) => {
                const data = transformedData.find(d => d.xPos === label);
                return `${data?.vendor} - ${data?.month}`;
              }}
            />
            <Legend verticalAlign="top" height={36} />
            <Bar dataKey="early" stackId="a" fill={colors.early} name="Early" />
            <Bar dataKey="onTime" stackId="a" fill={colors.onTime} name="On Time" />
            <Bar dataKey="delayed" stackId="a" fill={colors.delayed} name="Delayed" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

export default VendorPunctualityChart;