/**
 * Represents the status of a container ship and its associated container statuses and terminal events.
 *
 * Fields:
 * - name: The name of the ship.
 * - mmsi: The unique identifier of the ship (Maritime Mobile Service Identity).
 * - lat: The latitude of the ship's current location.
 * - lng: The longitude of the ship's current location.
 * - terminalEvents: An array of terminal events associated with the ship.
 *   - terminal: The terminal where the event occurs.
 *   - eta: The estimated time of arrival at the terminal.
 *   - ata: The actual time of arrival at the terminal (if applicable).
 *   - etd: The estimated time of departure from the terminal.
 *   - phase: The phase of the ship (e.g., 'INBOUND', 'WORKING', 'DOCKED').
 *   - erd: The earliest return date for cargo.
 *   - name: The name of the vessel for the event.
 *   - shipline: The name of the shipping line.
 *   - scac: The Standard Carrier Alpha Code of the shipping line.
 *   - inVoyage: The inbound voyage number.
 *   - outVoyage: The outbound voyage number.
 *   - service: The service route name.
 *   - berth: The berth number at the terminal.
 * - containerStatuses: An array of statuses for containers onboard the ship.
 *   - companyName: The name of the company owning the container.
 *   - containerNo: The identifier of the container.
 *   - reefer: Indicates whether the container is refrigerated (true, false, or null).
 *   - hazmat: Indicates if the container contains hazardous material (true, false, or null).
 *   - estimatedArrival: The estimated date of arrival for the container.
 *   - arrival: The actual date of arrival for the container (if applicable).
 *   - stage: The current stage of the container (e.g., 'IN_TRANSIT', 'READY_FOR_PICKUP').
 *   - status: The status of the container (e.g., 'ON_TIME').
 */
export interface ContainerStatusDto {
    name: string;
    mmsi: string;
    lat: number;
    lng: number;
    terminalEvents: TerminalEventDto[];
    containerStatuses: ContainerDetailsDto[];
}

export interface TerminalEventDto {
    terminal: string;
    eta: string;
    ata: string | null;
    etd: string;
    phase: string;
    erd: string;
    name: string;
    shipline: string;
    scac: string;
    inVoyage: string;
    outVoyage: string;
    service: string;
    berth: string;
}

export interface ContainerDetailsDto {
    companyName: string;
    containerNo: string;
    reefer: boolean | null;
    hazmat: boolean | null;
    estimatedArrival: string;
    arrival: string | null;
    stage: string;
    status: string | null;
}

export type ContainerStatusListDto = ContainerStatusDto[];


export const containerTestReferences: string[] = [
    "ABCD1234567",
    "EFGH2345678",
    "MNOP4567890",
    "QRST5678901",
    "UVWX6789012",
    "YZAB7890123",
    "CDEF8901234",
    "GHIJ9012345",
    "KLMN0123456",
    "OPQR1234568",
    "STUV2345679",
    "WXYZ3456780"
];
