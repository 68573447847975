import React from 'react';
import { 
  Box, 
  Tooltip, 
  Typography, 
  Paper,
  useTheme
} from '@mui/material';
import { Package } from 'lucide-react';

// Base DTO interfaces
export interface TerminalEventDto {
    terminal: string;
    eta: string;
    ata: string | null;
    etd: string;
    phase: string;
    erd: string;
    name: string;
    shipline: string;
    scac: string;
    inVoyage: string;
    outVoyage: string;
    service: string;
    berth: string;
}

export interface ContainerDetailsDto {
    companyName: string;
    containerNo: string;
    reefer: boolean | null;
    hazmat: boolean | null;
    estimatedArrival: string;
    arrival: string | null;
    stage: string;
    status: string | null;
}

export interface ContainerStatusDto {
    name: string;
    mmsi: string;
    lat: number;
    lng: number;
    terminalEvents: TerminalEventDto[];
    containerStatuses: ContainerDetailsDto[];
}

export type ContainerStatusListDto = ContainerStatusDto[];

// Component-specific interfaces
interface ContainerVisualizationProps {
    data: ContainerStatusListDto;
}

// Extended container type with ship information
interface ContainerWithShipInfo extends ContainerDetailsDto {
    shipName: string;
    shipMMSI: string;
    currentTerminal?: string;
}

// Stage groups interface for type safety
interface ContainerGroups {
    AT_PORT: ContainerWithShipInfo[];
    READY_FOR_PICKUP: ContainerWithShipInfo[];
    IN_TRANSIT: ContainerWithShipInfo[];
}

// Props for sub-components
interface ContainerIconProps {
    container: ContainerWithShipInfo;
}

interface ContainerColumnProps {
    title: string;
    containers: ContainerWithShipInfo[];
}

const ContainerVisualization: React.FC<ContainerVisualizationProps> = ({ data }) => {
  const theme = useTheme();

  const groupedContainers = React.useMemo((): ContainerGroups => {
    const allContainers = data.flatMap(ship => 
      ship.containerStatuses.map(container => ({
        ...container,
        shipName: ship.name,
        shipMMSI: ship.mmsi,
        currentTerminal: ship.terminalEvents[0]?.terminal
      }))
    );

    const getStatusPriority = (status: string | null): number => {
      if (!status || status === 'ON_TIME') return 0;
      if (status.includes('5_TO_10_HOURS')) return 1;
      if (status.includes('OVER_10_HOURS')) return 2;
      return 0;
    };

    const sortContainers = (containers: ContainerWithShipInfo[]): ContainerWithShipInfo[] => {
      return [...containers].sort((a, b) => 
        getStatusPriority(a.status) - getStatusPriority(b.status)
      );
    };

    return {
      AT_PORT: sortContainers(allContainers.filter(c => c.stage === 'AT_PORT')),
      READY_FOR_PICKUP: sortContainers(allContainers.filter(c => c.stage === 'READY_FOR_PICKUP')),
      IN_TRANSIT: sortContainers(allContainers.filter(c => c.stage === 'IN_TRANSIT'))
    };
  }, [data]);

  const getContainerColor = (status: string | null): string => {
    if (!status || status === 'ON_TIME') return theme.palette.success.main;
    if (status.includes('OVER_10_HOURS')) return theme.palette.error.main;
    if (status.includes('5_TO_10_HOURS')) return '#E0D000' //theme.palette.warning.main;
    return theme.palette.success.main;
  };

  const formatDate = (dateString: string | null): string => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const ContainerIcon: React.FC<ContainerIconProps> = ({ container }) => (
    <Tooltip
      title={
        <Box sx={{ p: 1 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
            {container.companyName}
          </Typography>
          <Typography variant="body2">Ship: {container.shipName}</Typography>
          <Typography variant="body2">MMSI: {container.shipMMSI}</Typography>
          {container.currentTerminal && (
            <Typography variant="body2">Terminal: {container.currentTerminal}</Typography>
          )}
          <Typography variant="body2">Container: {container.containerNo}</Typography>
          <Typography variant="body2">
            Reefer: {container.reefer === null ? 'Unknown' : container.reefer ? 'Yes' : 'No'}
          </Typography>
          <Typography variant="body2">
            Hazmat: {container.hazmat === null ? 'Unknown' : container.hazmat ? 'Yes' : 'No'}
          </Typography>
          <Typography variant="body2">Est. Arrival: {formatDate(container.estimatedArrival)}</Typography>
          <Typography variant="body2">Actual Arrival: {formatDate(container.arrival)}</Typography>
          <Typography variant="body2">Status: {container.status || 'On Time'}</Typography>
        </Box>
      }
    >
      <Box
        sx={{
          width: 64,
          height: 64,
          m: 0.5,
          borderRadius: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          backgroundColor: getContainerColor(container.status),
          position: 'relative',
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.05)'
          }
        }}
      >
        <Package color="white" size={48} />
        {container.reefer && (
          <Box
            sx={{
              position: 'absolute',
              top: 4,
              right: 4,
              width: 10,
              height: 10,
              borderRadius: '50%',
              border: '1px solid #000',
              backgroundColor: theme.palette.info.light
            }}
          />
        )}
        {container.hazmat && (
          <Box
            sx={{
              position: 'absolute',
              top: 4,
              left: 4,
              width: 10,
              height: 10,
              borderRadius: '50%',
              border: '1px solid #000',
              backgroundColor: theme.palette.error.light
            }}
          />
        )}
      </Box>
    </Tooltip>
  );

  const ContainerColumn: React.FC<ContainerColumnProps> = ({ title, containers }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="body2" sx={{ mt: 1, color: theme.palette.text.secondary }}>
        {containers.length} container{containers.length !== 1 ? 's' : ''}
      </Typography>
      <Paper 
        elevation={2}
        sx={{ 
          p: 2,
          flex: 1,
          minWidth: 120, 
          minHeight: 200,
          backgroundColor: theme.palette.grey[100],
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box 
          sx={{ 
            display: 'flex',
            flexDirection: 'column-reverse',
            flexWrap: 'wrap-reverse',
            alignContent: 'center',
            minHeight: '100%',
            maxHeight: 600,
            overflowY: 'auto'
          }}
        >
          {containers.map((container) => (
            <ContainerIcon 
              key={container.containerNo} 
              container={container}
            />
          ))}
        </Box>
      </Paper>
      <Typography variant="body1" sx={{ mb: 2, mt: 1 }}>
        {title}
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ p: 1, height: "100vh", display: "flex", flexDirection: "column" }}>
      <Typography variant="h4" sx={{ mb: 4, textAlign: 'center' }}>
        Container Status Overview
      </Typography>
      
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ width: 16, height: 16, borderRadius: 1, backgroundColor: theme.palette.success.main }} />
          <Typography variant="body2">On Time</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ width: 16, height: 16, borderRadius: 1, backgroundColor: '#E0D000' }} />
          <Typography variant="body2">5-10 Hours</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ width: 16, height: 16, borderRadius: 1, backgroundColor: theme.palette.error.main }} />
          <Typography variant="body2">&gt;10 Hours</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ width: 10, height: 10, borderRadius: '50%', border: '1px solid #000', backgroundColor: theme.palette.info.light }} />
          <Typography variant="body2">Reefer</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ width: 10, height: 10, borderRadius: '50%', border: '1px solid #000', backgroundColor: theme.palette.error.light }} />
          <Typography variant="body2">Hazmat</Typography>
        </Box>
      </Box>

      <Box sx={{flex: 1, display: 'flex', justifyContent: 'space-around', alignItems: 'stretch', gap: 4 }}>
        <ContainerColumn 
          title="At Port" 
          containers={groupedContainers.AT_PORT}
        />
        <ContainerColumn 
          title="Ready for Pickup" 
          containers={groupedContainers.READY_FOR_PICKUP}
        />
        <ContainerColumn 
          title="In Transit" 
          containers={groupedContainers.IN_TRANSIT}
        />
      </Box>
    </Box>
  );
};

export default ContainerVisualization;