import React, { useEffect, useRef, useState } from 'react';

const VantaBackground = () => {
    const [vantaEffect, setVantaEffect] = useState<any>(null);
    const vantaRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!vantaEffect && window.VANTA) {
            setVantaEffect(
                window.VANTA.FOG({
                    el: vantaRef.current,
                    mouseControls: true,
                    touchControls: true,
                    gyroControls: false,
                    minHeight: 200.00,
                    minWidth: 200.00,
                    highlightColor: 0x77ff,
                    midtoneColor: 0xa4ff,
                    baseColor: 0x351ccd
                  })
            );
        }
        // Cleanup effect when component unmounts
        return () => {
            if (vantaEffect) vantaEffect.destroy();
        };
    }, [vantaEffect]);

    return <div ref={vantaRef} style={{ width: '100vw', height: '100vh', position: 'absolute' }} />;
};

export default VantaBackground;
