// SessionContext.tsx
import React, { createContext, useContext, useState } from 'react';
import { useOidcCodeVerifier } from '../hooks/use_oidc_code_verifier';
import { useTokenExchange } from '../hooks/use_token_exchange';
import { useUserManager } from '../hooks/use_user_manager';
import { UserManagerSettings } from 'oidc-client-ts';
import { useNavigate } from 'react-router-dom';
import { User } from '../models/user';

interface SessionProviderProps {
    children: React.ReactNode;
}

interface SessionContextType {
    user: User | null;
    settings: UserManagerSettings;
    isInitialized: boolean;
    isAuthenticated: boolean;
    isAuthenticating: boolean;
    error: Error | null;
    dashboardUrl: string;
    login: () => void;
    logout: () => void;
    handleOidcStateChange: (state: string, code: string) => void;
}

const SessionContext = createContext<SessionContextType | undefined>(undefined);

export const SessionProvider: React.FC<SessionProviderProps> = ({ children }) => {
    const navigate = useNavigate()
    const [oidcCodeStr, setOidcCodeStr] = useState<string>('')
    const [oidcStateStr, setOidcStateStr] = useState<string>('')
    const [signInError, setSignInError] = useState<Error | null>(null)

    // Use the state string to get the code verifier from local storage
    const codeVerifier = useOidcCodeVerifier(oidcStateStr)

    // Use the UserManager to manage authentication flows
    const { authSettings, userManager, isUserManagerLoading, userManagerError, dashboardUrl } = useUserManager();

    // Perform the token exchange with our server when needed
    const { user, clearToken, isTokenLoading, tokenError } = useTokenExchange(oidcCodeStr, oidcStateStr, codeVerifier, authSettings.redirect_uri)

    const login = () => {
        if (userManager) {
            userManager
                .signinRedirect()
                .catch((err) => setSignInError(new Error(`Got error during sign in: ${err.message}`)))
        }
    };

    const logout = () => {
        // Removed for now, was causing timeout errors.  VPN? -DRI
        // if (userManager) {
        //     userManager.signoutSilent();
        // }
        clearToken();
        navigate("/")
    };


    const handleOidcStateChange = (state: string, code: string) => {
        console.log('OIDC state changed!', code, state)
        setOidcCodeStr(code)
        setOidcStateStr(state)
    };

    const error = signInError || userManagerError || tokenError

    return (
        <SessionContext.Provider
            value={{
                user,
                settings: authSettings,
                isInitialized: isUserManagerLoading,
                isAuthenticated: error === null && user?.isExpired === false,
                isAuthenticating: error === null && isTokenLoading,
                dashboardUrl,
                error,
                login,
                logout,
                handleOidcStateChange
            }}
        >
            {children}
        </SessionContext.Provider>
    );
};

/**
 * Custom React hook to access the session context.
 * 
 * This hook provides access to the session state, including the user's authentication status, user details,
 * token, and login/logout functions. It should only be used within a component wrapped by the `SessionProvider`.
 * 
 * @returns {SessionContextType} The session context value including user, token, and auth methods.
 * @throws Error if used outside of a `SessionProvider`.
 */
export const useSession = (): SessionContextType => {
    const context = useContext(SessionContext);
    if (context === undefined) {
        throw new Error('useSession must be used within a SessionProvider');
    }
    return context;
};
