import { QueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { MessageEnvelopeDto } from '../models/message_envelope_dto';
import { MonthlyPunctualityDto } from '../models/monthly_punctuality_stats_dto';
import { ContainerStatusListDto } from '../models/container_status_dto';

const BASE_PATH = '/api/cdl'

// These need to be converted to use React Query and Axios libs.  Until then, 
// they need the full BASE_PATH embedded in the URI path.
export const AUTH_CODE_URI_PATH = `${BASE_PATH}/auth/code`
export const AUTH_CONFIG_URI_PATH = `${BASE_PATH}/auth/config`

/**
 * The URI paths below are relative to the BASE_PATH above.
 * 
 * This works because the axiosInstance is created with a baseURL set to the BASE_PATH.
 * So it automatically expects all paths given to it for HTTP requests to be relative
 * to this baseURL.
 */
export const FDSP_REQUEST_URI_PATH = `/fdsp-request`
export const PUNCTUALITY_HISTORY_URI_PATH = `${FDSP_REQUEST_URI_PATH}/punctuality/history`
export const SHIP_POV_URI_PATH = `${FDSP_REQUEST_URI_PATH}/ship/port-of-virginia`

export const queryClient = new QueryClient()

const axiosInstance = axios.create({
  baseURL: BASE_PATH,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const tokenStr = localStorage.getItem('jwt') || '';
    const token = JSON.parse(tokenStr)
    if (token) {
      config.headers.Authorization = `Bearer ${token.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getPunctualityHistory = async () => {
  const response = await axiosInstance.get<MessageEnvelopeDto>(PUNCTUALITY_HISTORY_URI_PATH)
  const msgEvelope = response.data

  const msg = expectJsonArray(msgEvelope.msgContent.msgBody, 'Punctuality History')
  return msg as MonthlyPunctualityDto[]
}

export const getPortOfVirginiaShips = async (containers: string[]) => {
  const params = { containers };
  const response = await axiosInstance.get<MessageEnvelopeDto>(SHIP_POV_URI_PATH, { params })
  const msgEvelope = response.data

  const msg = expectJsonArray(msgEvelope.msgContent.msgBody, 'PoV Ships')
  return msg as ContainerStatusListDto
}

function isArrayOfObjects(data: any): data is object[] {
  return (
    Array.isArray(data) && data.every(item => typeof item === 'object' && item !== null)
  );
}

function expectJsonArray(jsonString: string, msgType?: string): object[] {
  // Example usage:
  try {
    const parsedData = JSON.parse(jsonString);

    if (!isArrayOfObjects(parsedData)) {
      console.error(`The parsed ${msgType || 'JSON'} is not an array of objects:`, jsonString);
      return []
    }

    return parsedData
  } catch (error) {
    console.error('Failed to parse JSON:', error);
    return []
  }
}
