import { ReactNode } from 'react'
import Typography from "@mui/material/Typography";
import Logo from '../common/assets/image/logo.png';
// import Logo from '../common/assets/svg/logo.svg';

const smokedGlassStyle = {
    background: 'rgba(40, 40, 40, 0.5)', // Semi-transparent black for smoky effect
    color: 'white', // Text color
    padding: '20px', // Padding for inner content spacing
    borderRadius: '15px', // Rounded corners
    backdropFilter: 'blur(10px)', // Blur effect to simulate glass
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
    border: '1px solid rgba(0, 0, 0, 0.1)', // Slight border for the frosted look
    margin: '20px', // Optional: margin for spacing
};

interface FdspGlassPlateProps {
    children?: ReactNode;
}

const FdspGlassPlate: React.FC<FdspGlassPlateProps> = ({ children }) => {

    return (
        <div style={smokedGlassStyle}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={Logo} alt='Logo' width='100' style={{ margin: "8px 2px" }} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h4" color="#DDD" noWrap component="span" sx={{ lineHeight: 1.3 }}>
                            Consolidated Delivery, LLC
                        </Typography>
                        <Typography variant="h6" color="#DDD" noWrap component="span" sx={{ lineHeight: 1 }}>
                            Control Center v{process.env.REACT_APP_VERSION}
                        </Typography>
                    </div>
                </div>
                <div style={{ marginTop: 20 }}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default FdspGlassPlate