/**
 * This modules contains utilty functions for working with strings.
 */

export type MaybeString = (string | undefined | null)

export const safeDescription = (description: MaybeString) => description || 'No description available.';

export const titleCase = (str: string): string =>
    str.split(/[\s_-]+/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')

export function dumbPluralize(count: number, noun: string): string {
    if (count === 1) {
        return `1 ${noun}`;
    } else {
        return `${count} ${noun}s`;
    }
}

const vendorNames = [
    'Consolidated', 'Blue Horizon', 'Pinnacle', 'Atlas Frieght', 'Road Master'
]

export const mapVendorName = (input: string): string => {
    // Check if string is empty
    if (!input) {
        return '';
    }

    // Get the last character
    const lastChar = input[input.length - 1];

    // Check if last character is a number
    if (!/\d/.test(lastChar)) {
        return input;
    }

    // Convert to number and adjust to zero-based index
    let index = parseInt(lastChar, 10) - 1;

    // Clamp the index between 0 and 4 (for 5 elements)
    index = Math.max(0, Math.min(index, 4));

    return vendorNames[index];
};