import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AuthCallbackPage from './auth_callback_page/AuthCallbackPage';
import { AUTH_CALLBACK_ROUTE, HOME_ROUTE } from './common/config/client_routes';
import { SessionProvider } from './common/providers/SessionContext';
import { queryClient } from './common/services/query_service';
import { connectorTheme } from './common/styles/theme';
import HomePage from './home_page/HomePage';
import { NotFoundError } from './common/components/messages';
import LogoutPage from './logout_page/LogoutPage';

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={connectorTheme}>
        <BrowserRouter>
          <SessionProvider>
            <Routes>
              <Route path={HOME_ROUTE} element={<HomePage />} />
              <Route path={AUTH_CALLBACK_ROUTE} element={<AuthCallbackPage />} />
              <Route path='/logout' element={<LogoutPage />} />
              <Route path="*" element={<NotFoundError message={`Page not found.`} />} />
            </Routes>
          </SessionProvider>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
