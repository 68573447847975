import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import { useSession } from "../common/providers/SessionContext";
import FdspGlassPlate from './FdspGlassPlate';
import BackgroundAnimation from './BackgroundAnimation';

export const NotAuthenticatedView = () => {
    const session = useSession()

    return (
        <div style={{ position: 'relative' }}>
            <BackgroundAnimation />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <FdspGlassPlate>
                    <AuthenticateButton onClick={session.login} />
                </FdspGlassPlate>
            </div>
        </div>
    )
}

interface AuthenticateButtonProps {
    onClick: () => void;
}

const AuthenticateButton: React.FC<AuthenticateButtonProps> = ({ onClick }) => {
    return (
        <Button
            variant="contained"
            sx={{
                backgroundColor: 'green',
                color: 'white',
                padding: '24px 36px',
                fontSize: '1rem',
                textTransform: 'none',
                '&:hover': {
                    backgroundColor: 'darkgreen',
                },
            }}
            size="large"
            startIcon={<LockIcon />}
            endIcon={<ArrowForwardIcon />}
            onClick={onClick}
        >
            <Typography variant='h5'>
                Authenticate with Local Identity Manager
            </Typography>
        </Button>
    );
};

export default NotAuthenticatedView