import { Box, Typography } from '@mui/material';
import { Anchor } from 'lucide-react';
import React from 'react';
import { Marker, Popup } from 'react-map-gl';
import { ContainerStatusDto, ContainerStatusListDto } from './ContainerVisualization';

interface ShipMarkersProps {
    data: ContainerStatusListDto;
}

const ShipMarkers: React.FC<ShipMarkersProps> = ({ data }) => {
    const [selectedShip, setSelectedShip] = React.useState<ContainerStatusDto | null>(null);

    return (
        <>
            {data.map(ship => (
                <Marker
                    key={ship.mmsi}
                    longitude={ship.lng}
                    latitude={ship.lat}
                    anchor="center"
                    onClick={e => {
                        e.originalEvent.stopPropagation();
                        setSelectedShip(selectedShip?.mmsi === ship.mmsi ? null : ship);
                    }}
                >
                    <Box sx={{
                        cursor: 'pointer',
                        color: theme => theme.palette.primary.main,
                        transition: 'transform 0.2s',
                        '&:hover': {
                            transform: 'scale(1.2)',
                        }
                    }}>
                        <Anchor size={24} />
                    </Box>
                </Marker>
            ))}

            {selectedShip && (
                <Popup
                    longitude={selectedShip.lng}
                    latitude={selectedShip.lat}
                    anchor="top"
                    onClose={() => setSelectedShip(null)}
                >
                    <Box sx={{ p: 2, minWidth: 200 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                            {selectedShip.name}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 0.5 }}>
                            MMSI: {selectedShip.mmsi}
                        </Typography>

                        {selectedShip.terminalEvents[0] && (
                            <>
                                <Typography variant="body2" sx={{ mb: 0.5 }}>
                                    Terminal: {selectedShip.terminalEvents[0].terminal}
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 0.5 }}>
                                    Phase: {selectedShip.terminalEvents[0].phase}
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 1 }}>
                                    ETA: {new Date(selectedShip.terminalEvents[0].eta).toLocaleString()}
                                </Typography>
                            </>
                        )}

                        <Typography variant="subtitle2" sx={{ mt: 1, mb: 0.5 }}>
                            Containers:
                        </Typography>
                        <Box component="dl" sx={{ m: 0 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                <Typography variant="body2" component="dt">Total:</Typography>
                                <Typography variant="body2" component="dd">
                                    {selectedShip.containerStatuses.length}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                <Typography variant="body2" component="dt">At Port:</Typography>
                                <Typography variant="body2" component="dd">
                                    {selectedShip.containerStatuses.filter(c => c.stage === 'AT_PORT').length}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                <Typography variant="body2" component="dt">Ready:</Typography>
                                <Typography variant="body2" component="dd">
                                    {selectedShip.containerStatuses.filter(c => c.stage === 'READY_FOR_PICKUP').length}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                <Typography variant="body2" component="dt">In Transit:</Typography>
                                <Typography variant="body2" component="dd">
                                    {selectedShip.containerStatuses.filter(c => c.stage === 'IN_TRANSIT').length}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Popup>
            )}
        </>
    );
};

export default ShipMarkers;