import TryAgainIcon from '@mui/icons-material/Recycling';
import { Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSession } from '../common/providers/SessionContext';
import { ConnectorBusySpinner } from '../common/components/ConnectorBusySpinner';

function AuthCallbackPage() {
    const session = useSession()
    const navigate = useNavigate()
    const [params] = useSearchParams()

    // This effect only runs once when the page loads
    useEffect(() => {
        const oidcCodeStr = params.get('code') ?? ''
        const oidcStateStr = params.get('state') ?? ''
        session.handleOidcStateChange(oidcStateStr, oidcCodeStr)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // This effect monitors the session changes
    useEffect(() => {
        if (session.isAuthenticated) {
            setTimeout(() => navigate('/'), 500)
        }
    }, [session, navigate])

    const handleContinue = () => navigate('/')

    const child = session.error !== null
        ? <ErrorMsg message={`${session.error.name}: ${session.error.message}`} onContinue={handleContinue} />
        : session.isAuthenticating
            ? <ConnectorBusySpinner label='Authenticating...' />
            : <ConnectorBusySpinner label='Loading Dashboard...' />

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ minHeight: '2em' }}>
                {child}
            </div>
        </div>
    )
}


interface ErrorMsgProps {
    message: string
    onContinue: () => void
}

const ErrorMsg = ({ message, onContinue }: ErrorMsgProps) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant='h5' color='error' >{message}</Typography>
            <div style={{ height: 8 }} />
            <Button
                onClick={onContinue}
                variant="contained"
                sx={{ textTransform: 'none', }}
                startIcon={<TryAgainIcon />}
            >
                <Typography>Try Again</Typography>
            </Button>
        </div>
    )
}

export default AuthCallbackPage
