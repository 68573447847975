import { Paper } from '@mui/material';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useState } from 'react';
import Map from 'react-map-gl';
import { usePovShipsQuery } from '../common/hooks/use_pov_ships_query';
import { usePunctualityQuery } from '../common/hooks/use_punctuality_query';
import { ContainerStatusListDto, containerTestReferences } from '../common/models/container_status_dto';
import ContainerVisualization from './ContainerVisualization';
import ShipMarkers from './ShipMarkers';
import VendorPunctualityChart from './VendorPunctualityChart';

// CSS Styles for the layout
const styles = {
    container: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        gridTemplateRows: '50% 50%', // Split the right content into two equal rows
        width: '100vw', // Full viewport width
        height: '100vh', // Full viewport height
        gap: '10px', // Optional spacing between elements
        backgroundColor: '#f0f0f0',
    },
    sidebar: {
        gridRow: '1 / span 2', // Sidebar takes up both rows
        backgroundColor: '#f0f0f0',
    },
    mapContainer: {
        gridColumn: '2 / 3', // Map takes the right side, first row
        gridRow: '1 / 2',
        backgroundColor: '#f0f0f0',
        padding: 10,
    },
    chartContainer: {
        gridColumn: '2 / 3', // Chart takes the right side, second row
        gridRow: '2 / 3',
        backgroundColor: '#f0f0f0',
        padding: 10,
    },
};

const DashboardView = () => {
    const { data: punctualityData } = usePunctualityQuery()
    const { data: povShipData } = usePovShipsQuery(containerTestReferences)

    return (
        <div style={styles.container}>
            <div style={styles.sidebar}>
                <ContainerVisualization data={povShipData || []} />
            </div>

            <div style={styles.mapContainer}>
                <Paper sx={{ width: "100%", height: "100%" }}>
                    <CdlMap shipData={povShipData || []} />
                </Paper>
            </div>

            <div style={styles.chartContainer}>
                <VendorPunctualityChart data={punctualityData || []} />
            </div>
        </div>
    );
};

interface CdlMapProps {
    shipData: ContainerStatusListDto;
}

function CdlMap({ shipData }: CdlMapProps) {
    const [viewState, setViewState] = useState({
        longitude: -75.4,
        latitude: 37.5,
        zoom: 6,
    });

    return (
        <Map
            {...viewState}
            onMove={(evt) => setViewState(evt.viewState)}
            mapStyle="mapbox://styles/mapbox/standard"
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        >
            <ShipMarkers data={shipData || []} />
        </Map>
    );
}

export default DashboardView

