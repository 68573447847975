import React, { useEffect } from 'react'
import { CenteredBusyMessage } from '../common/components/messages'
import { useSession } from '../common/providers/SessionContext'
import { Box } from '@mui/material'

const LogoutPage = () => {
    const session = useSession()

    useEffect(() => {
        setTimeout(() => session.logout(), 1000)
    }, [session])

    return (
        <Box height="100vh">
            <CenteredBusyMessage message='Logging out...' />
        </Box>
    )
}

export default LogoutPage